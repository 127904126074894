<template>
    <div class="footer_container">
        <div class="md-toolbar spacer">
            <!-- Hält einen Platz frei für den Footer -->
        </div>
        <div class="footer_">
            <md-toolbar class="footer">
                <div class="footer_left">
                    <md-button class="md-button md-xsmall-hide md-small-hide" to="/imprint/">Impressum</md-button>
                    <md-button class="md-button md-xsmall-hide md-small-hide" to="/privacypolicy/">Datenschutz</md-button>
                    <md-button class="md-button md-xsmall-hide md-small-hide" to="/faq/">FAQ</md-button>
                    <md-button class="md-button md-xsmall-hide md-small-hide" to="/philosophy/">Philosophie</md-button>
                    <md-button class="md-button md-xsmall-hide md-small-hide" to="/terms/">AGB</md-button>

                    <!--
                    <md-button onclick="alert('TODO: Kontaktformular??')" to="">Kontakt</md-button>
                    <md-button to="/tmp/">TMP</md-button>
                    -->
                </div>
                <div class="footer_right">
                    <md-button class="md-button" v-on:click="openmailer"> {{ this["infomail_" + nutzer_art] }}</md-button>
                </div>
            </md-toolbar>
        </div>
    </div>
</template>

<script>
export default {
    name: 'All_Footer',
    data: () => ({
        infomail_user: "info@paw-blueprint.de",
        infomail_partner: "info@paw-blueprint.de",
        infomail_admin: "info@paw-blueprint.de",
    }),
    computed: {
        nutzer_art: function () {
            let role = null
            let p = this.$route.path
            p = p.substring(1, p.indexOf("/", 1))
            role = p.length > 2 ? p : null;
            if (role === 'admin' || role === 'partner' || role === 'user') {
                return role
            } else {
                return 'user'
            }
        }
    },
    methods: {
        openmailer() {
            let art = this.nutzer_art.toString()
            window.open("mailto:" + this["infomail_" + art])
        }
    }
}
</script>

<style lang="scss" scoped>
.footer_ {
    //position: fixed;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 5;
}

.md-button {
    color: white;
}

.footer {
    //background-color: #00696d;
    background-color: #a6c532;
}

div.spacer {
    margin-top: 10px;
    position: relative;
}

.md-toolbar {
    height: 30px;
    width: 100%;
    //position: fixed;
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
}

.footer_left {
    width: 50%;
    float: left;
    flex-grow: 1;
    text-align: left;
    top: 0;
}

.footer_right {
    width: 50%;
    float: right;
    flex-grow: 1;
    text-align: right;
    top: 0;
    vertical-align: middle;
}

@media print {
    .footer_container {
        display: none;
    }
}
</style>
